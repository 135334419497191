<template>
  <div>
    <AddButton class="mb-3":onClick="() => {addWasteCode()}" caption="Adauga cod de deseu" v-if="hasAddButton"/>
    <v-row dense class="mb-3" v-if="showFilters">
      <v-col cols="12" md="6">
        <v-combobox clearable label="Filtreaza dupa cod deseu" v-model="wasteCodeFilter"
          density="compact" :items="activeWasteCodesPerWorkingPoint" item-text="displayName" underlined
          item-value="wasteCode._id" color="primary" @change="filterWasteCodesPerWP()" :disabled="disableFilters">
        </v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <v-combobox clearable label="Filtreaza dupa metoda de gestionare" v-model="discardTypeFilter"
          density="compact" :items="['ELIMINARE', 'VALORIFICARE']" underlined color="primary" @change="filterWasteCodesPerWP()"
          :disabled="disableFilters" >
        </v-combobox>
      </v-col>
    </v-row>
    <div class="wcPWP_table mt-6">
      <v-row  dense v-for="(wcPWP, idx) in paginatedResult" :key="idx" 
        class="pt-md-9 wcPWP_table_row" :style="{borderBottom: '1px solid #A4A4A4'}">
        <v-col cols="12" md="3">
          <v-row dense>
            <v-col cols="12" md="3" :class="{'d-none d-md-flex': wcPWP._id && !wcPWP.canBeDeleted, 'd-flex': wcPWP.canBeDeleted || !wcPWP._id}"
              class="flex-row flex-md-column justify-space-between align-center align-md-start" 
              :style="{minHeight: showEditMode ? '70px' : 'unset'}" >
              <div class="font-weight-bold table_text">Actiuni</div>
              <div class="d-flex pb-0" v-if="wcPWP.canBeDeleted || !wcPWP._id" :class="{'pb-md-5': !wcPWP._id}">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="deleteWCPWP(wcPWP)" icon color="red" v-bind="attrs" v-on="on" v-if="showEditMode">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col cols="12" md="3" v-if="wcPWP.wasteCode" class="d-flex flex-row flex-md-column justify-space-between align-center align-md-start" 
              :style="{minHeight: showEditMode ? '70px' : 'unset'}">
              <div class="font-weight-bold table_text">Cod deseu</div>
              <div class="table_text font-weight-bold">
                {{ wcPWP.wasteCode.code }}
              </div>
            </v-col>
            <v-col cols="12" md="6" v-if="wcPWP.wasteCode" class="d-flex flex-row flex-md-column justify-space-between align-center align-md-start" 
              :style="{minHeight: showEditMode ? '70px' : 'unset'}">
                <div class="font-weight-bold table_text">Denumire deseu</div>
                <div class="table_text font-weight-bold">
                  {{ wcPWP.wasteCode.name }}
                </div>
            </v-col>
            <v-col cols="12" md="9" v-if="!wcPWP.wasteCode" 
              class="d-flex flex-row flex-md-column justify-space-between align-center align-md-start" 
              :style="{minHeight: showEditMode ? '70px' : 'unset'}">
                <div class="d-flex align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text ">Cod deseu/ Denumire deseu</span> 
                </div>
                <div class="table_cell_content">
                  <v-autocomplete class="ml-0 mr-0 table_text black_placeholder" v-model="wcPWP.wasteCode" placeholder="Cauta dupa cod sau denumire" 
                  hide-selected text-color="white" :items="getAvailableWasteCodes()" item-text="code" item-value="_id" item 
                  @change="addNewWasteCode(wcPWP.wasteCode)" :search-input.sync="searchWasteCodes" no-filter return-object>
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-autocomplete>
                </div>
            </v-col>
          </v-row>
        </v-col>

          <v-col cols="12" md="9">
            <v-row dense>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}" >
                <div class="d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text mr-1 mr-md-0">Alias deseu</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>Completeaza un nume pentru deseu pe care sa ti-l amintesti, acesta te ajuta sa identifici 
                      usor deseurl pe care l-ai predat. Acest nume nu va aparea in rapoarte.</span>
                  </v-tooltip>
                </div>
                <div class=" d-flex justify-space-between align-center table_cell_content">
                  <!-- <div> -->
                    <v-text-field v-if="showEditMode" type="text" v-model="wcPWP.alias" placeholder="Nume alternativ" 
                      class="table_text" :disabled="!wcPWP._id" @blur="saveAlias(wcPWP)" :rules="[
                      v =>!v || ( v && v.length <= 15) || `Aliasul trebuie sa contina cel mult 15 de caractere`
                    ]"  />
                    <span v-else class="table_text">{{wcPWP.alias}}</span>
                  <!-- </div> -->
                  <!-- <v-tooltip top v-if="showEditMode">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="buttonAligned" @click="updateWasteCode(wcPWP)" icon color="accent" v-bind="attrs" v-on="on" :disabled="!wcPWP._id">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>Salveaza</span>
                  </v-tooltip> -->
                </div>

              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class="d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text mr-1 mr-md-0" >Metoda gestionare</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Alege metoda de gestionare a deseului:<br />
                      Eliminare - atunci cand deseurile ajung la groapa de gunoi sau cand sunt arse fara a genera o valoare<br />
                      Valorificare - atunci cand deseurile sunt reciclate sau incinerate cu scopul de a genera valoare (energie termica sau altele)
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-if="!wcPWP._id" v-model="discardType" class="table_text" :items="discardTypes"
                    placeholder="Alege" disabled/>
                  <v-select v-else v-model="wcPWP.discardType" class="table_text" :items="discardTypes"
                    placeholder="Alege" @input="changeMethod($event, wcPWP)"/>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.discardType }}</span>
                </div>

              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class="d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text mr-1 mr-md-0">Cod Valorificare/ Eliminare</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Alege codul de Valorificare sau Eliminare al deseului in functie de metoda de gestionare aleasa.
                      In general acest cod il primesti de la colector. Daca nu il primesti, alege unul din lista.<br />
                      Exemple:<br />
                      D1 - pentru deseuri ce ajung la groapa de gunoi;<br />
                      R5 - pentru deseuri reciclabile ce sunt predate unui reciclator;<br />
                      R12 - pentru deseuri ce sunt predate unui colector ce la randul lui le da mai departe
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-if="!wcPWP._id || !wcPWP.discardType" disabled class="table_text" :items="[]"
                    placeholder="Alege metoda gestionare" />
                  <v-select v-else-if="wcPWP.discardType == 'ELIMINARE'" clearable v-model="wcPWP.codEliminare" 
                    :items="listsByCategory(ListCategories.CODURI_ELIMINARE)" item-text="code" item-value="_id"
                    return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id"
                    placeholder="Alege" class="table_text">
                    <template slot="item" slot-scope="{ item, tile }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                  <v-select v-else-if="wcPWP.discardType == 'VALORIFICARE'" clearable v-model="wcPWP.codValorificare" 
                    :items="listsByCategory(ListCategories.CODURI_VALORIFICARE)" item-text="code" item-value="_id" 
                    return-object @input="updateWasteCode(wcPWP)" placeholder="Alege" 
                    class="table_text" :disabled="!wcPWP._id">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                  
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text" v-if="wcPWP.discardType == 'ELIMINARE'">{{ wcPWP.codEliminare ? wcPWP.codEliminare.code : '' }}</span>
                  <span class="table_text" v-if="wcPWP.discardType == 'VALORIFICARE'">{{ wcPWP.codValorificare ? wcPWP.codValorificare.code : '' }}</span>
                  <!-- <span class="table_text" v-else></span> -->
                </div>

              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text mr-1 mr-md-0" >Stoc initial</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Acest camp trebuie completat numai daca la inceputul raportarilor ai avut stoc din deseul selectat.<br /> 
                      In caz contrar, daca cantitatile toate au fost gestionate si predate unui partener, acest camp nu trebuie completat
                    </span>
                  </v-tooltip>
                </div>
                <div class="d-flex justify-space-between align-center table_cell_content" v-if="showEditMode">
                  <!-- <div > -->
                    <v-text-field type="number" v-model="wcPWP.initialStock" class="table_text" placeholder="Stoc la inceputul anului"
                      :disabled="!wcPWP._id" @blur="saveInitialStock(wcPWP)" :rules="[
                      v => !v || Number(v) >= 0 || `Stocul trebuie sa fie pozitiv`,
                      v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                      ]" />
                  <!-- </div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="buttonAligned" @click="updateWasteCode(wcPWP)" icon color="accent" v-bind="attrs" v-on="on" :disabled="!wcPWP._id">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>Salveaza</span>
                  </v-tooltip> -->
                </div>
                <div class="d-flex justify-space-between align-center table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.initialStock }}</span>
                </div>

              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="font-weight-bold table_text mr-1 mr-md-0" >Unitate de masura</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Unitatea de masura in care predai deseul catre parteneri:<br />
                      MC sau L pentru deseurile estimate. 1 MC = 1000 L<br />
                      KG sau TO pentru deseurile cantarile.
                      1 to = 1000 kg
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.unitMeasure" class="table_text" :items="listsByCategory(ListCategories.UNITATE_MASURA)"
                    placeholder="Alege unitatea" :disabled="!wcPWP._id"
                    item-text="code" item-value="_id" return-object @change="updateWasteCode(wcPWP)">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.unitMeasure ? wcPWP.unitMeasure.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Evaluare cantitate</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Daca deseurile sunt cantarite la predare atunci trebuie sa alegi cantarita.<br />
                      Daca cantitatea deseurilor este etimata atunci alegi ESTIMATA. 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.evalType" :items="evalTypes" class="table_text" placeholder="Alege"
                    item-text="name" item-value="_id" @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.evalType ? wcPWP.evalType.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Stare fizica</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Alegi starea fizica a deseurilor generate.<br />
                      Exemplu:<br />
                      Deseuri menajere, plastic, hartie, carton, metal etc - starea fizica Solid<br />
                      Deseuri de ulei, combustibili etc - stare fizica Lichid 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.stareFizica" :items="listsByCategory(ListCategories.STARE_FIZICA)"
                    class="table_text" placeholder="Alege" :disabled="!wcPWP._id"
                    item-text="code" item-value="_id" return-object @change="updateWasteCode(wcPWP)">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b>
                      </p>
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.stareFizica ? wcPWP.stareFizica.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Tip stocare</span>
                  <!-- <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      
                    </span>
                  </v-tooltip> -->
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.stockingType" class="table_text" placeholder="Alege"
                    :items="listsByCategory(ListCategories.TIP_STOCARE)" :item-text="item=>`${item.code} (${item.name})`" item-value="_id" 
                    return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.stockingType ? wcPWP.stockingType.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Destinatie</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Alege destinatia finala a deseului, cum va fi gestionat acesta:<br />
                      DO - depozitul de gunoi al orasului/ comunei - este de fapt groapa de gunoi - daca acesta este deseu menajer sau va ajunge la groapa de gunoi<br />
                      Vr - valorificare prin agenti economici autorizati , daca desul va fi reciclat<br />
                      Ve - valorificare energetica prin agenti economici autorizati - daca deseurl va fi incinerat cu scopul de a genera energie<br />
                      I incinerare in scopul eliminarii - daca sunt deseuri periculoase ce vor fi arse pentru eliminarea lor finala.
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-tooltip top max-width="250px" v-if="wcPWP.destination">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on">
                        <v-select v-model="wcPWP.destination" class="table_text" placeholder="Alege"
                          :items="listsByCategory(ListCategories.DESTINATIE)" :item-text="item=>`${item.code} (${item.name})`" item-value="_id" 
                          return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                          <template slot="item" slot-scope="{ item }">
                            <p>
                              <b> {{ item.code }}</b> {{ `(${item.name})` }}
                            </p>
                          </template>
                        </v-select>
                      </div>
                    </template>
                    <span >{{`${wcPWP.destination.code} (${wcPWP.destination.name})`}}</span>
                  </v-tooltip>
                  <v-select v-else v-model="wcPWP.destination" class="table_text" placeholder="Alege"
                    :items="listsByCategory(ListCategories.DESTINATIE)" :item-text="item=>`${item.code} (${item.name})`" item-value="_id" 
                    return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>  
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.destination ? wcPWP.destination.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Tip transport</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Alege tipul transportul ce va fi folosit pentru deseuri:<br />
                      Autospeciala pentru deseurile menajere si cele periculoase<br />
                      Autonespeciala pentru deseurile nepericuloase 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-tooltip top max-width="250px" v-if="wcPWP.carType">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on">
                        <v-select v-model="wcPWP.carType" class="table_text" placeholder="Alege"
                          :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :item-text="item=>`${item.code} (${item.name})`" item-value="_id" 
                          return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                          <template slot="item" slot-scope="{ item }">
                            <p>
                              <b> {{ item.code }}</b> {{ `(${item.name})` }}
                            </p>
                          </template>
                        </v-select>
                      </div>
                    </template>
                    <span >{{`${wcPWP.carType.code} (${wcPWP.carType.name})`}}</span>
                  </v-tooltip>
                  <v-select v-else v-model="wcPWP.carType" class="table_text" placeholder="Alege"
                    :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :item-text="item=>`${item.code} (${item.name})`" item-value="_id" 
                    return-object @change="updateWasteCode(wcPWP)" :disabled="!wcPWP._id">
                    <template slot="item" slot-scope="{ item }">
                      <p>
                        <b> {{ item.code }}</b> {{ `(${item.name})` }}
                      </p>
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.carType ? wcPWP.carType.code : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Destinatar</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Completeaza cu partenerul la care vor ajunge deseurile generate de tine. Poate fi acelasi cu transportatotul sau nu. 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.partnerDestination" class="table_text" placeholder="Alege destinatar"
                    :items="partnersByWorkingPoint(wcPWP.partnerDestination)" item-text="name" item-value="_id" 
                    @change="updateWasteCode(wcPWP, true)" :disabled="!wcPWP._id">
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title class="noDataButton">
                          Nu ai niciun partener adaugat pentru acest punct de lucru.
                          <AddButton :onClick="() => {openPartnerDialog(wcPWP, 'DESTINATAR')}" caption="Adauga partener" />
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item v-if="partnersByWorkingPoint(wcPWP.partnerDestination).length">
                      <AddButton :onClick="() => {openPartnerDialog(wcPWP, 'DESTINATAR')}" caption="Adauga partener nou" />
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.partnerDestination ? wcPWP.partnerDestination.name : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Transportator</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Completeaza partenerul care va transporta deseurile de la tine la destinatar, poate fi acelasi cu destinatarul sau nu. 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select v-model="wcPWP.partnerTransportator" class="table_text" placeholder="Alege transportator"
                    :items="partnersByWorkingPoint(wcPWP.partnerTransportator, true)" 
                    item-text="name" item-value="_id" @change="updateWasteCode(wcPWP, true)" :disabled="!wcPWP._id">
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title class="noDataButton">
                          Nu ai niciun partener transportator adaugat pentru acest punct de lucru.
                          <AddButton :onClick="() => {openPartnerDialog(wcPWP, 'TRANSPORTATOR')}" caption="Adauga partener transportator" />
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item v-if="partnersByWorkingPoint(wcPWP.partnerTransportator, true).length">
                      <AddButton :onClick="() => {openPartnerDialog(wcPWP, 'TRANSPORTATOR')}" caption="Adauga partener transportator nou" />
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.partnerTransportator ? wcPWP.partnerTransportator.name : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class="d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Mijloc de transport</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Completeaza la nivel de partener numarul masinii ce va transporta deseurile de la tine 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select :disabled="!wcPWP.partnerTransportator" v-model="wcPWP.partnerCar" 
                    class="table_text" :placeholder="!wcPWP.partnerTransportator ? 'Alege transportator' : 'Alege'"
                    :items="partnerById(wcPWP.partnerTransportator) ? partnerById(wcPWP.partnerTransportator).partnerCars : []" 
                    item-text="plate" item-value="_id" return-object @change="updateWasteCode(wcPWP)"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title class="noDataButton">
                          Nu ai nicio masina adaugata pentru acest partener.
                          <AddButton :onClick="() => {openPartnerDialogForUpdate(wcPWP)}" caption="Adauga masina" />
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item v-if="partnerById(wcPWP.partnerTransportator) && partnerById(wcPWP.partnerTransportator).partnerCars.length">
                      <AddButton :onClick="() => {openPartnerDialogForUpdate(wcPWP)}" caption="Adauga masina noua" />
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.partnerCar ? wcPWP.partnerCar.plate : '' }}</span>
                </div>
              </v-col>
              <v-col class="d-flex flex-row flex-md-column justify-space-between table_col_custom" 
                :class="{'justify-md-start': !showEditMode}">
                <div class=" d-flex justify-start justify-md-space-between align-center align-md-start table_cell_header">
                  <span class="mr-1 mr-md-0 font-weight-bold table_text" >Sofer</span>
                  <v-tooltip bottom max-width="250px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>mdi-information-outline</v-icon>
                    </template>
                    <span>
                      Completeaza la nivel de partener numele soferului ce transporta deseurile de la tine. 
                    </span>
                  </v-tooltip>
                </div>
                <div class="table_cell_content" v-if="showEditMode">
                  <v-select :disabled="!wcPWP.partnerTransportator" v-model="wcPWP.partnerDriver" 
                    class="table_text" :placeholder="!wcPWP.partnerTransportator ? 'Alege transportator' : 'Alege'"
                    :items="partnerById(wcPWP.partnerTransportator) ? partnerById(wcPWP.partnerTransportator).partnerDrivers : []" 
                    item-text="name" item-value="_id" return-object @change="updateWasteCode(wcPWP)"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title class="noDataButton">
                          Nu ai niciun sofer adaugat pentru acest partener.
                          <AddButton :onClick="() => {openPartnerDialogForUpdate(wcPWP)}" caption="Adauga sofer" />
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item v-if="partnerById(wcPWP.partnerTransportator) && partnerById(wcPWP.partnerTransportator).partnerDrivers.length">
                      <AddButton :onClick="() => {openPartnerDialogForUpdate(wcPWP)}" caption="Adauga sofer nou" />
                    </template>
                  </v-select>
                </div>
                <div class="table_cell_content" v-else>
                  <span class="table_text">{{ wcPWP.partnerDriver ? wcPWP.partnerDriver.name : '' }}</span>
                </div>
              </v-col>

            </v-row>
          </v-col>
        
      </v-row>
      <v-row dense justify="end" align="center">
        <span class="mr-4 table_text" style="font-size: 12px;">Rows per page</span>
        <v-select v-model="itemsPerPage" class="table_text mr-4" :items="[5, 10, 25, 50]"  style="max-width: 50px;"/>
        <span class="mr-4" style="font-size: 12px;">{{ page + ' / ' + totalPages}}</span>
        <v-btn class="mr-1" icon @click="page--"
          :disabled="page <= 1" >
          <v-icon color="grey">mdi-chevron-left</v-icon>
        </v-btn>
        
        <v-btn icon @click="page++"
          :disabled="page >= totalPages" >
          <v-icon color="grey">mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </div>
    <CreatePartner v-if="partnerDialog" :forField="fieldToAddPartner" :closeDialog="closePartnerDialog" 
      :workingPoint="workingPoint" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" :partnerToUpdate="partnerToUpdate"
      :updateCarsAndDrivers="updateCarsAndDrivers"/>
    <InfoDialog v-if="showInfoDialog" :infoText="infoText" :closeDialog="closeInfoDialog"/>
    <div v-if="overlay" transition="fade-transition" class="loading_overlay">
      <v-progress-circular
        indeterminate
        size="64"
        color="secondary"
      ></v-progress-circular>
      <span style="color: #FFFFFF;" class="mt-3" >Iti completam noi setarile deseului...</span>
    </div>
  </div>
</template>
<script>
import {
  ListCategories
} from '../../common/ListCategories';
import AddButton from '../Buttons/AddButton.vue';
import CreatePartner from '../Modals/Create/CreatePartner.vue';
import InfoDialog from '../Modals/InfoDialog.vue';
export default {
  props: [
    "workingPoint",
    "hasAddButton",
    "showEditMode",
    "showFilters",
  ],
  data() {
    return {
      wcToUpdate: [],
      partnerDialog: false,
      partnerToUpdate: null,
      updateCarsAndDrivers: false,
      fieldToAddPartner: null,
      currentWasteCodePWP: null,
      searchWasteCodes: '',
      filters: {},
      wasteCodeFilter: null,
      discardTypeFilter: null,
      discardType: '',
      discardTypes: ['ELIMINARE', 'VALORIFICARE'],
      evalTypes: [
        {
          _id: 'A', code: 'A', name: 'Cantarita'
        },
        {
          _id: 'B', code: 'B', name: 'Estimata'
        }
      ],
      page: 1,
      itemsPerPage: 10,
      disableFilters: false,
      overlay: false,
      infoText: '',
      showInfoDialog: false
    };
  },
  components: {
    AddButton,
    CreatePartner,
    InfoDialog
  },
  computed: {
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    ListCategories() {
      return ListCategories
    },
    wasteCodes() {
      return this.$store.state.wasteCodes.wasteCodes
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    partners() {
      return this.$store.state.partners.partners
    },
    activeWasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.activeWasteCodesPerWorkingPointForFilter
    },
    initialStocks() {
      return this.$store.state.wasteCodesPerWorkingPoint.initialStocks
    },
    initialAliases() {
      return this.$store.state.wasteCodesPerWorkingPoint.initialAliases
    },
    // wcDiscardTypes() {
    //   let wcDiscardTypes = {}
    //   this.wasteCodesPerWorkingPoint.forEach(e => {
    //     if(e._id) {
    //       let discardType = '';
    //       if(e.codValorificare) {
    //         discardType = 'VALORIFICARE'
    //       }
    //       if(e.codEliminare) {
    //         discardType = 'ELIMINARE'
    //       }
    //       wcDiscardTypes[e._id] = discardType
    //     }
    //   });
    //   return wcDiscardTypes;
    // },
    totalPages() {
      if(this.wasteCodesPerWorkingPoint.length) {
        return Math.ceil(this.wasteCodesPerWorkingPoint.length / this.itemsPerPage)
      }

      return 0;
    },
    paginatedResult() {
      if(this.wasteCodesPerWorkingPoint && this.wasteCodesPerWorkingPoint.length) {
        let totalPages = Math.ceil(this.wasteCodesPerWorkingPoint.length / this.itemsPerPage)
        if(this.page > totalPages) {
          this.page = 1;
        }
        let start = (this.page * this.itemsPerPage) - this.itemsPerPage;
        let end = this.page * this.itemsPerPage;
        return this.wasteCodesPerWorkingPoint.slice(start, end)
      }
      return [];
    }
  },
  watch: {
    workingPoint() {
      this.resetTable()
    },
    searchWasteCodes(newVal) {
      this.timeout = setTimeout(() => {
        this.findWasteCodes()
      }, 500);
    },

  },
  methods: {
    openInfoDialog() {
      let propsArr = ['codValorificare', 'codEliminare', 'stareFizica', 'unitMeasure', 
        'evalType', 'destination', 'stockingType', 'carType']
      let filteredProps = Object.keys(this.wasteCodesPerWorkingPoint[0]).filter(e => propsArr.includes(e))
      if(filteredProps.length) {
        this.infoText = 'Datele au fost populate cu ajutorul AI. Te rugam sa le verifici si sa completezi tu Destinatar si Transportator.'
      } else {
        this.infoText = 'Ne pare rau datele nu au putut fi completate automat, te rugam sa le completezi tu.'
      }
      this.showInfoDialog = true
    },
    closeInfoDialog() {
      this.showInfoDialog = false
    },
    saveAlias(wcPWP) {
      if(wcPWP._id) {
        let initialAlias = this.initialAliases[wcPWP._id]
        if(wcPWP.alias && wcPWP.alias.length > 15) {
          return
        }
        if(!wcPWP.alias && !initialAlias) {
          return
        }
        if(wcPWP.alias != initialAlias) {
          this.updateWasteCode(wcPWP)
        }
      }
    },
    saveInitialStock(wcPWP) {
      if(wcPWP._id) {
        let initialStock = this.initialStocks[wcPWP._id]
        if(!(Number(wcPWP.initialStock) >= 0 || !wcPWP.initialStock)) {
          return
        }
        if(wcPWP.initialStock) {
          if(Number(wcPWP.initialStock) < 0) {
            return
          }
          if(!/^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(wcPWP.initialStock)) {
            return
          }
        }
        if(initialStock != wcPWP.initialStock) {
          if(Number(initialStock) >= 0) {
            if(!wcPWP.initialStock) {
              wcPWP.initialStock = 0
            }
          }
          this.updateWasteCode(wcPWP)
        }
      }
    },
    changeMethod(val, wcPWP) {
      if(val && val == 'VALORIFICARE') {
        if(wcPWP.codEliminare) {
          wcPWP.codEliminare = null
        }
      } else if(val && val == 'ELIMINARE') {
        if(wcPWP.codValorificare) {
          wcPWP.codValorificare = null
        }
      }
    },
    resetTable() {
      this.filters = {}
      this.wasteCodeFilter = null
      this.discardTypeFilter = null
      this.discardType = ''
      this.page = 1
    },
    filterWasteCodesPerWP() {
      if (this.wasteCodeFilter) {
        this.filters['wasteCode'] = this.wasteCodeFilter.wasteCode._id
      } else {
        delete this.filters.wasteCode
      }

      if (this.discardTypeFilter) {
        this.filters['discardType'] = this.discardTypeFilter
      } else {
        delete this.filters.discardType
      }

      this.getWasteCodesForWorkingPoint()
    },
    getAvailableWasteCodes() {
      let takenWasteCodes = this.wasteCodesPerWorkingPoint.map(w => w.wasteCode ? w.wasteCode._id : null).filter(a => a)
      return this.wasteCodes.filter(wc => !takenWasteCodes.includes(wc._id))
    },
    findWasteCodes() {
      this.$store.dispatch("fetchWasteCodes", {
        search: this.searchWasteCodes
      })
    },
    async addWasteCode() {
      let alreadyAddedWasteCode = this.wasteCodesPerWorkingPoint.find(w => !w.wasteCode)
      if (!alreadyAddedWasteCode) {
        if(this.showFilters) {
          this.resetTable();
          await this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
            workingPointId: this.workingPoint._id,
            filters: this.filters
          })
          this.disableFilters = true;
        } else {
          this.page = 1;
        }
        
        this.$store.dispatch("addEmptyWasteCode")
      }
    },
    addNewWasteCode(wasteCode) {
      this.overlay = true
      this.wasteCodesPerWorkingPoint = this.wasteCodesPerWorkingPoint.filter(wc => wc.wasteCode && wc.wasteCode.code != wasteCode.code)
      this.$store.dispatch("createWasteCodePerWorkingPoint", {
        workingPointId: this.workingPoint._id,
        wasteCode: wasteCode,
        cb: () => {
          this.overlay = false
          this.disableFilters = false;
          this.discardType = ''
          this.openInfoDialog()
        }
      })
    },
    updateWasteCode(item, updatingPartner) {
      if (updatingPartner) {
        item.partnerCar = null
        item.partnerDriver = null
      }
      this.$store.dispatch("updateWasteCodePerWorkingPoint", {
        id: item._id,
        model: item,
        cb: () => {
          // this.$store.dispatch('fetchWorkingPointsData', {});
        }
      })
    },
    deleteWCPWP(wcPWP) {
      if(!wcPWP._id) {
        this.$store.dispatch('deleteEmptyWasteCode');
        this.discardType = ''
        this.disableFilters = false;
      } else {
        this.$store.dispatch('deleteWasteCodePerWorkingPoint', wcPWP._id);
      }
    },
    // getDiscardType(wcPWP) {
    //   if(wcPWP._id) {
    //     return this.wcDiscardTypes[wcPWP._id]
    //   } else {
    //     return this.discardType
    //   }
    // },
    listsByCategory(category) {
      return this.lists[category]
    },
    partnersByWorkingPoint(addedPartner, onlyTransportator) {
      let partners = []
      if (!addedPartner) {
        partners = this.partners.filter(p => p.isActive)
      } else {
        partners = this.partners.filter(p => (p.isActive || p._id == addedPartner._id))
      }
      if(onlyTransportator) {
        partners = partners.filter(p => {
          return p.types.map(t => t.code).includes('Transportator')
        })
      } else {
        // sa aiba cel putin un tip care sa nu fie transp
        partners = partners.filter(p => {
          return p.types.map(t => t.code).find(c => c != 'Transportator')
        })
      }
      return partners
    },
    partnerById(partner) {
      if (!partner) {
        return
      }
      if (partner._id) {
        partner = partner._id
      }
      return this.partners.find(p => {
        return p._id == partner
      })
    },
    openPartnerDialog(wasteCodePWP, fieldToAddPartner) {
      this.currentWasteCodePWP = wasteCodePWP
      this.fieldToAddPartner = fieldToAddPartner
      this.partnerDialog = true
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.fieldToAddPartner = null
      this.partnerToUpdate = null
      this.updateCarsAndDrivers = false
    },
    openPartnerDialogForUpdate(wasteCodePWP) {
      this.currentWasteCodePWP = wasteCodePWP
      this.partnerToUpdate = this.partnerById(wasteCodePWP.partnerTransportator)
      this.fieldToAddPartner = 'TRANSPORTATOR'
      this.updateCarsAndDrivers = true
      this.partnerDialog = true
    },
    getWasteCodesForWorkingPoint() {
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.workingPoint._id,
        filters: this.filters
      })
    },
    refreshData() {
      this.$store.dispatch('fetchPartners', {
        workingPointId: this.workingPoint._id,
      });
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.workingPoint._id,
        filters: this.filters
      })
    },
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.$store.dispatch("fetchWasteCodes", {
        search: this.searchWasteCodes
      })
  }
};
</script>
<style scoped>
  .wcPWP_table {
    border-top: 1px solid #A4A4A4;
  }
  .wcPWP_table .v-input.theme--light:not(.v-input--is-disabled, .black_placeholder) ::placeholder {
    color: #979696  !important
  }
  .wcPWP_table .black_placeholder.v-input ::placeholder {
    color: #000000 !important;
  }
  .wcPWP_table .font-weight-bold {
    color: rgba(0, 0, 0, .6);
  }
  .wcPWP_table .wcPWP_table_row:nth-of-type(odd) {
    background-color: #e7f5f8;
  }

  .wcPWP_table .wcPWP_table_row:hover {
    background-color: #eee;
  }

  .table_text {
    font-size: 12px;
  }
  .table_col_custom {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .table_cell_header {
    max-width: 40%;
  }
  .table_cell_content {
    max-width: 55%;
    min-width: 55%;
    text-align: end;
  }
  @media only screen and (min-width: 961px) {
    .table_cell_header {
      max-width: unset;
    }
    .table_cell_content {
      max-width: unset;
      min-width: 70%;
      text-align: start;
    }
    .wcPWP_table {
      border-top: 0px solid #A4A4A4;
    }
    .table_col_custom {
      width: 20%;
      min-width: 20%;
      max-width: 20%;
    }
  }
  @media only screen and (min-width: 1265px) {
    .table_col_custom {
      min-width: 14.28%;
      max-width: 14.28%;
    }
  }
  .loading_overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, .6);;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>