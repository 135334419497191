var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Adauga "+_vm._s(_vm.companyOnly ? 'companie' : 'punct de lucru'))])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[(_vm.workingPointType == 'WORKING_POINT')?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Trebuie sa adaugati detaliile punctului de lucru unde va desfasurati activitatea. ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.companyOnly)?_c('v-select',{attrs:{"label":"Tip punct de lucru *","items":_vm.workingPointTypes},on:{"change":function($event){return _vm.changeWorkingPointType()}},model:{value:(_vm.workingPointType),callback:function ($$v) {_vm.workingPointType=$$v},expression:"workingPointType"}}):_vm._e()],1)],1),_c('v-form',{ref:"form",staticClass:"w-full"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.workingPointType == "WORKING_POINT")?_c('v-autocomplete',{attrs:{"label":"Alege organizatia *","items":_vm.companies,"color":"blue-grey-lighten-2","item-text":"companyName","item-value":"_id","rules":[function (v) { return !!v || "Alege organizatia"; }],"item":"","return-object":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}}):_vm._e()],1),_c('v-col',{class:{'order-1': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":_vm.getFieldLabel('name'),"required":"","label":_vm.getFieldLabel('name'),"loading":_vm.waitForAnaf,"disabled":_vm.waitForAnaf,"rules":[
                  function (v) { return !!v || "Completeaza numele"; },
                  function (v) { return v && v.length <= 50 || "Numele trebuie sa contina cel mult 50 de caractere"; }
                ]},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Trebuie sa completati numele punctului de lucru la care va desfasurati activitatea. "),_c('br'),_vm._v(" Acesta nu este unul legal, poate fi Magazin, Birou, numele magazinului, numele restaurantului, numele companiei daca aveti un singur punct de lucru etc ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-col',{class:{'order-3': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":_vm.getFieldLabel('city'),"required":"","label":_vm.getFieldLabel('city'),"loading":_vm.waitForAnaf,"disabled":_vm.waitForAnaf,"rules":[
                  function (v) { return !!v || "Completeaza orasul"; },
                  function (v) { return v && v.length <= 100 || "Orasul trebuie sa contina cel mult 50 de caractere"; }
                ]},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Trebuie sa completati orasul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})],1),_c('v-col',{class:{'order-4': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":_vm.getFieldLabel('county'),"required":"","label":_vm.getFieldLabel('county'),"loading":_vm.waitForAnaf,"disabled":_vm.waitForAnaf,"rules":[
                function (v) { return !!v || "Completeaza judetul"; },
                function (v) { return v && v.length <= 100 || "Judetul trebuie sa contina cel mult 50 de caractere"; }
              ]},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Trebuie sa completati judetul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.county),callback:function ($$v) {_vm.$set(_vm.model, "county", $$v)},expression:"model.county"}})],1),_c('v-col',{class:{'order-5': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":_vm.workingPointType == 'WORKING_POINT' ? '12' : '4'}},[_c('v-text-field',{attrs:{"type":"text","placeholder":_vm.getFieldLabel('address'),"required":"","label":_vm.getFieldLabel('address'),"loading":_vm.waitForAnaf,"disabled":_vm.waitForAnaf,"rules":[
                  function (v) { return !!v || "Completeaza adresa"; },
                  function (v) { return v && v.length <= 100 || "Adresa trebuie sa contina cel mult 50 de caractere"; }
                ]},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Trebuie sa completati adresa la care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1),_c('v-col',{class:{'order-0': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":_vm.getCUILabel(),"required":"","label":_vm.getCUILabel(),"rules":_vm.getCUIRUles(),"disabled":_vm.waitForAnaf},on:{"blur":function($event){_vm.workingPointType == 'COMPANY' ? _vm.getPartnerDataFromAnaf() : null}},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Se completeaza numai daca punctul de lucru are Cod fiscal diferit de cel al companiei. ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.vatId),callback:function ($$v) {_vm.$set(_vm.model, "vatId", $$v)},expression:"model.vatId"}})],1),_c('v-col',{class:{'order-2': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Nr Registrul comertului","label":"Nr Registrul Comertului","loading":_vm.waitForAnaf,"disabled":_vm.waitForAnaf},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Se completeaza numai daca punctul de lucru are Numar Registrul Comertului diferit de cel al companie. ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.regCom),callback:function ($$v) {_vm.$set(_vm.model, "regCom", $$v)},expression:"model.regCom"}})],1),_c('v-col',{class:{'order-6': _vm.workingPointType == 'COMPANY'},attrs:{"cols":"12","md":_vm.workingPointType == 'WORKING_POINT' ? '4' : '12'}},[_c('v-autocomplete',{attrs:{"label":"Alege tipul de activitate","items":_vm.activityTypes,"color":"blue-grey-lighten-2"},scopedSlots:_vm._u([(_vm.workingPointType == 'WORKING_POINT')?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1856626425)},[_vm._v(" Alegeti tipul de activitate ce este desfasurata la punctul de lucru ")])]},proxy:true}:null],null,true),model:{value:(_vm.model.activityType),callback:function ($$v) {_vm.$set(_vm.model, "activityType", $$v)},expression:"model.activityType"}})],1)],1),(_vm.workingPointType == 'WORKING_POINT')?_c('v-row',{staticClass:"pt-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Autorizatie de mediu")]),_vm._v(" (doar pentru punctele de lucru unde se aplica):")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Numar autorizatie mediu","label":_vm.getAuthLabel(),"rules":_vm.getAuthRules(),"clearable":""},model:{value:(_vm.model.noAuth),callback:function ($$v) {_vm.$set(_vm.model, "noAuth", $$v)},expression:"model.noAuth"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var click = ref.on.click;
return [_c('v-text-field',{attrs:{"label":_vm.getStartAuthLabel(),"rules":_vm.getStartAuthRules(),"clearable":""},on:{"click":click},model:{value:(_vm.model.startDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "startDateAuth", $$v)},expression:"model.startDateAuth"}})]}}],null,false,1234011304),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"scrollable":"","actions":""},model:{value:(_vm.model.startDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "startDateAuth", $$v)},expression:"model.startDateAuth"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v("OK")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var click = ref.on.click;
return [_c('v-text-field',{attrs:{"label":_vm.getEndAuthLabel(),"rules":_vm.getEndAuthRules(),"clearable":""},on:{"click":click},model:{value:(_vm.model.endDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "endDateAuth", $$v)},expression:"model.endDateAuth"}})]}}],null,false,3060974343),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"scrollable":"","actions":""},model:{value:(_vm.model.endDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "endDateAuth", $$v)},expression:"model.endDateAuth"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v("OK")])],1)],1)],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"cardActions"},[_c('v-btn',{staticClass:"mainButtonYes",attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Adauga")]),_c('v-btn',{staticClass:"mainButtonNo",attrs:{"type":"button","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Renunta")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }