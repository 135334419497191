<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px" @keydown.esc="close()">
      <v-card>
        <v-card-title>
          <span class="headline mb-2">{{ editedItem ? "Editeaza" : "Adauga" }} CRM</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form">
            <v-row>
              <v-text-field ref="focusMe" type="text" label="Nume client (optional)" v-model="model.name" outlined
                persistent-placeholder class="px-2 w-third" />
              <v-text-field type="text" label="Telefon" v-model="model.phone" outlined persistent-placeholder
                class="px-2 w-third" />
              <v-text-field type="text" label="Email" v-model="model.email" outlined persistent-placeholder
                class="px-2 w-third" />
            </v-row>
            <v-row>
              <v-select v-model="model.subject" :items="crmSubjects" persistent-placeholder label="Subiect *" outlined
                class="px-2 w-third"></v-select>
              <v-text-field type="text" label="CUI - pentru preluare ANAF (optional)" v-model="vatId" outlined
                :loading="anafLoading"
                persistent-placeholder class="px-2 w-third" />
              <v-text-field type="text" label="Nume companie (optional)" v-model="model.companyName" outlined
              :key="companyKey"
                persistent-placeholder class="px-2 w-third" />
            </v-row>

            <v-row>
              <v-select v-model="model.status" :items="crmStatuses" label="Status *" persistent-placeholder outlined
                class="px-2 w-third"></v-select>
              <v-text-field v-model="model.followUp" type="date" persistent-placeholder
                label="Data follow-up (optional)" outlined class="px-2 w-third" />

              <v-select v-model="model.plan" :items="plans" persistent-placeholder label="Plan de interes (optional)"
                outlined class="px-2 w-third"></v-select>
            </v-row>
            <v-row>
              <v-textarea rows="3" v-model="model.observations" :label="'Observatii'" outlined persistent-placeholder
                class="px-2 w-third" />
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import AddButton from "../Buttons/AddButtonText.vue";
export default {
  components: {
    AddButton,
  },
  props: ["closeDialog", "editedItem", "getTableData"],
  data: () => ({
    companyKey: 0,
    anafLoading: false,
    dialog: true,
    vatId: null,
    inputRules: [(v) => !!v || "Campul este obligatoriu"],
    model: {},
    plans: [
      {
        text: "Trial",
        value: "7093",
      },
      {
        text: "Advanced",
        value: "7094",
      },
      {
        text: "Advanced Yearly",
        value: "7304",
      },
    ],
  }),
  watch: {
    vatId(newVal) {
      if(this.vatId && this.vatId.length > 3) {
        this.timeout = setTimeout(() => {
        this.getDataAnaf();
      }, 500);
      }
     
    },
  },
  computed: {
    crmSubjects() {
      return this.$store.state.crm.crmSubjects.map((c) => c.subject);
    },
    crmStatuses() {
      return this.$store.state.crm.crmStatuses.map((c) => c.status);
    },
  },
  methods: {
    async getDataAnaf() {
      this.anafLoading = true;
      this.$store.dispatch("fetchCompanyFromANAF", {
        vatId: this.vatId,
        cb: (companyName) => {
      
          this.model.companyName = companyName;
          this.companyKey++
          this.anafLoading = false;
         
        },
      });
    },
    close() {
      this.closeDialog();
    },

    save() {
      if (this.$refs.form.validate()) {
        this.model.vatId = this.vatId;
        if (this.model._id) {
          this.$store.dispatch("updateCRM", {
            model: this.model,
            cb: () => {
              this.getTableData();
            },
          });
        } else {
          this.$store.dispatch("createCRM", {
            model: this.model,
            cb: () => {
              this.getTableData();
            },
          });
        }

        this.close();
      }
    },
  },

  created() {
    if (this.editedItem) {
      this.model = {
        ...this.editedItem,
      };
      this.vatId = this.editedItem.vatId;
    }
  },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {

  .w-half,
  .w-third,
  .w-quarter,
  .w-75 {
    width: 100% !important;
  }
}
</style>
