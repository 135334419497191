<template>
<auth-layout>
  <CRMLayout v-if="isAdmin" />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import CRMLayout from '../Layouts/CRMLayout.vue';
export default {

  data() {
    return {
      impKey: 1
    };
  },
  components: {
    AuthLayout,
    CRMLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userImp() {
      return this.$store.state.auth.userImp
    }
  },
  watch: {
   
  },
  methods: {
    // setImpersonatedUser(userId) {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       if (userId) {
    //         localStorage.setItem('userImpId', userId);
    //         resolve(); 
    //       } else {
    //         reject('No user info provided');
    //       }
    //     } catch (error) {
    //       console.log("error", error)
    //       reject(error);
    //     }
    //   });
    // },
    // impersonateUser(user) {
    //   this.setImpersonatedUser(user._id).then(() => {
      
    //     this.impKey++
    //     this.$router.push('/')
    //     this.$store.dispatch('fetchUserById', user._id);
    //     console.log('User impersonated successfully');
    //   }).catch((error) => {
    //     console.error('Error impersonating user:', error);
    //   });
    // },
   
  },
  created() {
  
  }
};
</script>
