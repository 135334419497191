<template>
  <div style="margin: 10px">

    <LayoutHeader title="Puncte de lucru" :dontDistrurbFree="true" />
    <div class="layoutBody">
      <AddButton v-if="canAddWorkingPoint && canPerformOrganizationChanges" :onClick="() => { openWorkingPointDialog() }"
        caption="Adauga" />
      <AddButton v-if="userAdvanced && !canAddWorkingPoint && canPerformOrganizationChanges"
        :onClick="() => { buyWorkingPoint() }" caption="Achizitioneaza puncte de lucru" />
      <v-data-table :items="workingPoints" :headers="workingPointHeaders" :footer-props="{
      itemsPerPageOptions: [25, 50, 100],
    }">
        <template v-slot:top>
          <v-row class="pt-4 pb-2">
            <v-col cols="12" md="6">
              <v-text-field v-model="search" label="Cauta ( Denumire / CUI companie parinte)" clearable dense
              @input="searchWorkpoints($event)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select clearable label="Filtreaza dupa status ACTIV" v-model="filters.isActive" 
                :items="['DA', 'NU']" color="primary" @change="filterWorkpoints" dense>
              </v-select>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.wasteCodesPerWorkingPointNo`]="props">
          <!-- @click="openWasteCodesPerWorkingPoint(props.item)" -->
          <v-btn color="primary" to='/wasteCodesPerWorkingPoint'>
            {{ props.item.wasteCodesPerWorkingPointNo }} deseuri - {{ canEdit(props.item) ? 'gestioneaza' : 'vezi' }}
          </v-btn>
        </template>
        <template v-slot:[`item.isActive`]="props">
          <p class="tableData">
          <div class="flex">
            <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive"
              :disabled="props.item.isDefault || userDisabled || !canPerformOrganizationChanges"
              @click="updateWorkingPointActive(props.item)"></v-checkbox>
          </div>
          </p>
        </template>
        <template v-slot:[`item.isDefault`]="props">
          <p class="tableData">
          <div class="flex">
            <v-checkbox class="" style="align-items: center;" :input-value="props.item.isDefault"
              :disabled="props.item.isDefault || userDisabled || !canPerformOrganizationChanges"
              @click="makeDefault(props.item)"></v-checkbox>
          </div>
          </p>
        </template>
        <template v-slot:[`item.createdAt`]="props">
          {{ parseDate(props.item.createdAt) }}
        </template>
        <template v-slot:[`item.actions`]="props" >
          <p class="tableData" >
          <div class="flex" v-if="canEdit(props.item)" >
            <div class="flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openUpdateDialog(props.item)" icon color="primary" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>Editeaza</span>
              </v-tooltip>
            </div>
            <div class="flex" v-if="props.item.canBeDeleted && !props.item.isDefault">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDeleteDialog(props.item)" icon color="red" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Sterge</span>
              </v-tooltip>
            </div>
          </div>
          </p>
        </template>
      </v-data-table>
    </div>
    <DeleteDialog v-if="deleteDialog" :closeDialog="closeDeleteDialog" :deleteAction="deleteWorkingPoint"
      itemName="punctul de lucru" :item="currentWorkingPoint" />
    <CreateWorkingPoint v-if="createDialog" :closeDialog="closeCreateDialog" :companies="companies" :createCb="deleteFilters"/>
    <UpdateWorkingPoint v-if="updateDialog" :closeDialog="closeUpdateDialog" :model="currentWorkingPoint" />
    <!-- <WasteCodesPerWorkingPointDialog v-if="wasteCodesDialog" :closeDialog="closeWasteCodesPerWorkingPoint"
      :workingPoint="currentWorkingPoint" /> -->
  </div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from '../components/Buttons/AddButton.vue';
import CreateWorkingPoint from '../components/Modals/Create/CreateWorkingPoint.vue';
import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import UpdateWorkingPoint from '../components/Modals/UpdateWorkingPoint.vue';
import WasteCodesPerWorkingPointDialog from '../components/Modals/WasteCodesPerWorkingPointDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    CreateWorkingPoint,
    UpdateWorkingPoint,
    WasteCodesPerWorkingPointDialog,
    DeleteDialog
  },
  data() {
    return {
      search: '',
      filters: {
        isActive: null
      },
      createDialog: false,
      // wasteCodesDialog: false,
      currentWorkingPoint: null,
      updateDialog: false,
      deleteDialog: false,
      workingPointHeaders: [
        {
          text: "Actiuni",
          value: 'actions'
        },
        {
          text: "Denumire",
          value: 'name'
        },
        {
          text: "Companie parinte",
          value: 'company.companyName'
        },
        {
          text: "CUI",
          value: 'vatId'
        },
        {
          text: "Reg Com",
          value: 'regCom'
        },
        {
          text: "Judet",
          value: 'county'
        },
        {
          text: "Localitate",
          value: 'city'
        },
        {
          text: "Adresa",
          value: 'address'
        },
        {
          text: "Coduri de deseu",
          value: 'wasteCodesPerWorkingPointNo'
        },
        {
          text: "Activ",
          value: 'isActive'
        },
        {
          text: "Default",
          value: 'isDefault'
        },
        // {
        //   text: "Data creare",
        //   value: 'createdAt'
        // }
      ]
    };
  },
  watch: {
  },
  computed: {
    companies() {
      return this.$store.state.companies.companies
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints
    },
    user() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },
    allowedWorkingPoints() {
      if (this.userImp) {
        return this.userImp.allowedWorkingPoints
      }
      return this.user ? this.user.allowedWorkingPoints : 0
    },
    canAddWorkingPoint() {
      if (this.userAdvanced) {
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      if (this.userFree) {
        // return this.workingPoints.length < 1
        //TRIAL HAS ALL FEATURES ENABLED
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      return false
    },
    userPhone() {
      return this.userImp && this.userImp.phoneNumber ? this.userImp.phoneNumber : this.user.phoneNumber
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    },
    userEmail() {
      return this.userImp && this.userImp.email ? this.userImp.email : this.user.email
    },
    userCUI() {
      return this.companies[this.companies.length - 1].vatId
    }
  },
  methods: {
    canEdit(workingPoint) {
      if(!this.user.permissions || !this.user.permissions.length) {
        return true
      }
      return this.user.permissions.some(permission => {
        return permission.workingPoints.includes(workingPoint._id) &&
          permission.role == 'MANAGER'
      })
    },
    buyWorkingPoint() {
      let url = `https://registrul-deseurilor.ro/asp-payment-box/?product_id=7094&email=${this.userEmail}&cui=${this.userCUI}&nume=${this.userName}&phone=${this.userPhone}`
      window.open(url, "_blank");
    },
    openUpdateDialog(workingPoint) {
      this.currentWorkingPoint = workingPoint
      this.updateDialog = true
    },
    closeUpdateDialog() {
      this.updateDialog = false
      this.currentWorkingPoint = null
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateWorkingPointActive(workingPoint) {
      this.$store.dispatch("updateWorkingPointActive", {
        workingPointId: workingPoint._id,
      })
      workingPoint.isActive = !workingPoint.isActive
    },
    deleteWorkingPoint(workingPoint) {
      this.$store.dispatch('deleteWorkingPointById', workingPoint._id);
    },
    openDeleteDialog(workingPoint) {
      this.currentWorkingPoint = workingPoint
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.currentWorkingPoint = null
      this.deleteDialog = false
    },
    makeDefault(workingPoint) {
      if (workingPoint.isDefault) {
        // workingPoint.isDefault = true
        return
      }
      this.$store.dispatch("makeWorkingPointDefault", {
        workingPointId: workingPoint._id,
        cb: () => {
          this.$store.dispatch('fetchWorkingPoints', {
            search: this.search,
            filter: this.filters,
          });
        }
      })
      this.workingPoints.map(wp => wp.isDefault = false)
      workingPoint.isDefault = false
      workingPoint.isActive = true
    },
    openWorkingPointDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    // openWasteCodesPerWorkingPoint(workingPoint) {
    //   this.wasteCodesDialog = true
    //   this.currentWorkingPoint = workingPoint
    // },
    // closeWasteCodesPerWorkingPoint() {
    //   this.wasteCodesDialog = false
    //   this.currentWorkingPoint = null
    //   this.$store.dispatch('fetchWorkingPoints', {
    //     search: this.search,
    //     filter: this.filters,
    //   });
    // },
    searchWorkpoints(event) {
      setTimeout(() => {
        if(this.search == event) {
          this.filterWorkpoints()
        }
      }, 500);
    },
    filterWorkpoints() {
      this.$store.dispatch('fetchWorkingPoints', {
        search: this.search,
        filter: this.filters,
      });
    },
    deleteFilters() {
      this.search = '';
      this.filters = {
        isActive: null
      }
    },
    // resetAllFilters() {
    //   this.search = '';
    //   this.filters = {
    //     isActive: null
    //   }
    //   this.filterWorkpoints();
    // },
  },
  created() {
    this.$store.dispatch('fetchWorkingPoints');
    this.$store.dispatch('fetchCompanies');
  }
};
</script>
